import React, { useState, useContext, useEffect } from "react";
import Styles from "./SelectLoadForm.module.scss";

import { AppContext } from "@vahak/core/dist/app-context";
import { LorryFormVals } from "../form-types";
import { FormikProps } from "formik";
import classNames from "classnames";
import AppBtn from "@vahak/core/dist/components/app-btn/AppBtn";

import PlusBlueIcon from "@vahak/core/dist/icons/nf_plusBlue.svg";
import RightArrowBlueIcon from "@vahak/core/dist/icons/nf_rightArrowBlue.svg";
import { LorryListLorryDetailTypes, LorryMarketDetails } from "@vahak/core/dist/custom-types/lorry";
import { LOAD_STATUS } from "@vahak/core/dist/constants/loadStatus";
import InfiniteScroll from "react-infinite-scroll-component";
import { LorryDetailsDataType } from "../../../../../services/lorry/useGetLorryDetailsService";
import { LorryCardV2 } from "../../../../../services/lorry/commonType/type";
import { GA4EventNames } from "@vahak/core/dist/constants/event-names";
import { useEventTrackerService } from "@vahak/core/dist/_services/hooks/useEventTrackerService";
import { useGetLoadAllDetailsListV2 } from "../../../../../services/load/useGetLoadAllDetailsV2/useGetLoadAllDetailsV2";
import { NoSuccessErrorHandler } from "@vahak/core/dist/constants";
import { LoadAllDetails } from "../../../../../services/load/useGetLoadAllDetailsV2/type";
import { getPrefilledDataFromMarketLoadResponse } from "../../../../../services/ticket-system/useLoadVerification";
import { loadCreationFormFieldsTypes } from "../../../../posting/helpers/constants";
import { filterFalseObjValues } from "@vahak/core-ui/dist/methods/object";
import { getPriceString } from "@vahak/core-ui/dist/methods";
import Loader from "@vahak/core/dist/components/loader";
import LoaderPlaceholderSwitch from "../../../../../layout/LoaderPlaceholderSwitch";
import NoRecordFound from "../../../../dashboard/list-wrapper/no-record-found/NoRecordFound";
import NoUserFound from "@vahak/core/dist/icons/Result-not-found.svg";

export interface SelectFormProps {
    formikConfig: FormikProps<LorryFormVals>;
    lorryDetails?: LorryCardV2["lorry_detail"] | LorryMarketDetails | LorryDetailsDataType | LorryListLorryDetailTypes;
    onClickPostNewLoad?: () => void;
    lorryInfoSlot?: React.ReactNode;
}

const SelectLoadForm = ({ formikConfig, lorryDetails, onClickPostNewLoad, lorryInfoSlot }: SelectFormProps) => {
    const { isMobile, companyId, customerCompanyId } = useContext(AppContext);

    const [loadListData, setLoadListData] = useState<LoadAllDetails[]>();
    const [inActiveLoadListData, setInActiveLoadListData] = useState<LoadAllDetails[]>();

    const { type } = (lorryDetails as LorryDetailsDataType) || {};
    const { type_value, source_info: { city_id = 0 } = {} } = (lorryDetails as LorryCardV2["lorry_detail"]) || {};

    const { sendGAandMoEngageEvent } = useEventTrackerService();

    const trackEvent = (name: string) => {
        sendGAandMoEngageEvent({
            name: name,
            data: {
                source_screen: "choose_your_load",
                from_screen: "lorry_market"
            }
        });
    };
    const handleSelect = (load: LoadAllDetails) => {
        const { load_detail } = load;
        load_detail?.id &&
            formikConfig.setValues({
                ...formikConfig.values,
                loadId: load_detail?.id!,
                loadStatus: load_detail?.status,
                selectedLoad: {
                    id: load_detail?.id,
                    values: getPrefilledDataFromMarketLoadResponse(load) as loadCreationFormFieldsTypes
                },
                isTapNgoLoad: load_detail?.is_tap_n_go
            });
    };

    const resetSelectedLoad = () => {
        formikConfig.setValues({
            ...formikConfig.values,
            loadId: undefined,
            loadStatus: undefined,
            selectedLoad: undefined
        });
    };

    const toggleAddNewLoad = () => {
        resetSelectedLoad();
        onClickPostNewLoad?.();
    };

    const commonParamForLoadQuery: Parameters<typeof useGetLoadAllDetailsListV2>[0] = {
        vehicleType: [String(type_value || type)],
        companyId: Number(customerCompanyId || companyId),
        ...(!!city_id && {
            cityIds: [city_id]
        })
    };

    const activeLoadListResponse = useGetLoadAllDetailsListV2(
        {
            ...commonParamForLoadQuery,
            loadStatus: [LOAD_STATUS.ACTIVE]
        },
        {
            ...NoSuccessErrorHandler,
            onSuccess: setLoadListData
        }
    );

    const inActiveLoadListResponse = useGetLoadAllDetailsListV2(
        {
            ...commonParamForLoadQuery,
            loadStatus: [LOAD_STATUS.EXPIRED, LOAD_STATUS.COMPLETED]
        },
        {
            ...NoSuccessErrorHandler,
            onSuccess: setInActiveLoadListData
        }
    );

    const totalActiveLoadRecords = activeLoadListResponse?.data?.pages?.[0]?.data?.total_records ?? 0;
    const totalInActiveLoadRecords = inActiveLoadListResponse?.data?.pages?.[0]?.data?.total_records ?? 0;

    const isNoActiveLoad = activeLoadListResponse?.isFetched && !totalActiveLoadRecords;
    const isNoInActiveLoad = inActiveLoadListResponse?.isFetched && !totalInActiveLoadRecords;

    const isEmptyList = !totalActiveLoadRecords && !totalInActiveLoadRecords;
    const isLoading = isEmptyList && activeLoadListResponse?.isFetching && inActiveLoadListResponse?.isFetching;

    useEffect(() => {
        isNoActiveLoad && isNoInActiveLoad && toggleAddNewLoad();
    }, [isNoInActiveLoad, isNoInActiveLoad]);

    const getLoadCard = (ld: LoadAllDetails) => {
        const { load_detail = {} } = ld;
        const badgeStrings = Object.keys(
            filterFalseObjValues({
                Assured: load_detail?.is_tap_n_go,
                Expired: load_detail?.status === LOAD_STATUS.EXPIRED,
                Completed: load_detail?.status === LOAD_STATUS.COMPLETED
            })
        ).join(" + ");

        const MaterialSlot = (
            <div className={Styles.materialQnty}>
                <h5>
                    {load_detail.available_for} - {load_detail.material_type} - &nbsp;
                    {load_detail?.expected_amount
                        ? getPriceString(load_detail?.expected_amount)
                        : "Price Not available"}
                </h5>
            </div>
        );

        const DotSlot = <span className={Styles.dot}></span>;
        return (
            <>
                <div
                    className={classNames(
                        Styles.saveloadCard,
                        formikConfig.values.loadId === load_detail.id && Styles.isSelected
                    )}
                    key={load_detail.id}
                    onClick={() => handleSelect(ld)}
                >
                    {!isMobile && <span className={Styles.dateTime}>{load_detail.posted_time}</span>}
                    {!!badgeStrings?.length && <span className={Styles.assuredBadge}>{badgeStrings}</span>}
                    {isMobile ? (
                        <div className={Styles.textBox}>
                            {DotSlot}
                            {MaterialSlot}

                            <div className={Styles.cityWrap}>
                                <div className={Styles.col}>
                                    <h4>{load_detail.source}</h4>
                                </div>
                                <div className={Styles.col}>
                                    <h4>{load_detail.destination}</h4>
                                </div>
                            </div>
                            <span className={Styles.dateTime}>{load_detail.posted_time}</span>
                        </div>
                    ) : (
                        <div className={Styles.textBox}>
                            {DotSlot}
                            <div className={Styles.cityWrap}>
                                <div className={Styles.col}>
                                    <h4>{load_detail.source}</h4>
                                </div>
                                <div className={Styles.col}>
                                    <RightArrowBlueIcon />
                                    <h4>{load_detail.destination}</h4>
                                </div>
                            </div>
                            {MaterialSlot}
                        </div>
                    )}
                </div>
            </>
        );
    };

    return (
        <div className={Styles.main}>
            <div className={classNames(Styles.addNewBtnContainer)}>
                {lorryInfoSlot}
                <AppBtn
                    className={Styles.addNewBtn}
                    onClick={() => {
                        toggleAddNewLoad();
                        trackEvent?.(GA4EventNames.MARKET_PLACE["post_load_button_click"]);
                    }}
                >
                    <PlusBlueIcon />
                    Add New Load
                </AppBtn>
            </div>
            <LoaderPlaceholderSwitch
                isEmptyList={isEmptyList}
                isLoading={isLoading}
                placeholderSlot={
                    <NoRecordFound
                        containerClassname={Styles.noRecordFoundWrapper}
                        icon={<NoUserFound />}
                        message={"No Loads Found"}
                    />
                }
            >
                <div className={classNames(Styles.selectSaveLods)} id="loadsListWrapper">
                    {!!loadListData?.length && (
                        <>
                            <h3>Select from Active Loads</h3>
                            <InfiniteScroll
                                dataLength={totalActiveLoadRecords}
                                next={activeLoadListResponse?.fetchNextPage}
                                hasMore={!!activeLoadListResponse?.hasNextPage}
                                loader={loadListData?.length ? <div>Loading...</div> : <div>No Active Loads Found</div>}
                                scrollableTarget="loadsListWrapper1"
                                className={Styles.sheet}
                            >
                                {loadListData?.map((ld) => getLoadCard(ld))}
                            </InfiniteScroll>
                        </>
                    )}

                    {!!inActiveLoadListData?.length && (
                        <>
                            <h3>Select from Expired/Completed Loads</h3>
                            <InfiniteScroll
                                dataLength={totalInActiveLoadRecords}
                                next={inActiveLoadListResponse?.fetchNextPage}
                                hasMore={!!inActiveLoadListResponse?.hasNextPage}
                                loader={
                                    inActiveLoadListData?.length ? (
                                        <div>Loading...</div>
                                    ) : (
                                        <div>No Active Loads Found</div>
                                    )
                                }
                                scrollableTarget="loadsListWrapper2"
                                className={Styles.sheet}
                            >
                                {inActiveLoadListData?.map((ld) => getLoadCard(ld))}
                            </InfiniteScroll>
                        </>
                    )}
                </div>
            </LoaderPlaceholderSwitch>
        </div>
    );
};

export default SelectLoadForm;
