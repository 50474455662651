import { Form, Formik, FormikProps } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "@vahak/core/dist/app-context";
import {
    GA4EventNames,
    GA4LoadPostingSource,
    LoadPostingFormEvents,
    VWOEvents
} from "@vahak/core/dist/constants/event-names";

import { ROUTES } from "@vahak/core/dist/constants/routeStrings";
import Lorry from "../../../../marketplace-new/lorry/Lorry";
import Button from "@vahak/core-ui/dist/components/Button";
import { LORRY_CATEGORIES_ENUM, getLorryDetailsByType } from "@vahak/core/dist/constants/lorryTypes";
import { LOAD_STATUS } from "@vahak/core/dist/constants/loadStatus";
import { GenericObject } from "@vahak/core/dist/custom-types/generic-object";
import FormContainer from "../form-container/FormContainer";
import { toast } from "@vahak/core/dist/components/toast/toast";

import { LorryFormVals } from "../form-types";
import { lorryBidSteps } from "./lorryBidSteps";
import { getLorryBidValidationSchema, getLorryValidationSchema } from "./validationSchema";
import SelectLoadForm from "./SelectLoadForm";
import { handleSubmitLorryBidResponse, useSubmitLorryBid } from "@vahak/core/dist/_services/hooks/useBidService";
import { sleep } from "@vahak/core/dist/methods/sleep";

import {
    gaSendEvent,
    sendPageSenseEvent,
    getFBEventId,
    sendFbCapiEvent
} from "@vahak/core/dist/_services/user-analytics.service";
import { useRouter } from "next/router";
import Styles from "./LorryBidForm.module.scss";

//Hooks
import { useQueryClient } from "react-query";
import queryNames from "../../../../../services/queryNames";

//Methods
import { LorryDetailsDataType } from "../../../../../services/lorry/useGetLorryDetailsService";
import { LorryListLorryDetailTypes, LorryMarketDetails } from "@vahak/core/dist/custom-types/lorry";
import { BiddingFormContext, BID_ACTIONS } from "../../../../../BiddingFormContextProvider";
import { LorryCardV2 } from "../../../../../services/lorry/commonType/type";
import useValidateUserStatus from "../../../../../hooks/useValidateUserStatus";
import { useEventTrackerService } from "@vahak/core/dist/_services/hooks/useEventTrackerService";
import { removeNonNumeric, stringOperations } from "@vahak/core-ui/dist/methods/string";

import {
    ILoadLorryPostingContextData,
    POSTING_FORM_MODAL_ORIGINATION_PAGE,
    useLoadLorryPosting
} from "../../../../../context/load-lorry-posting";
import { LOAD_CREATION_FORM_IDS } from "../../../../posting/helpers/constants";
import { LorryBidFormVals } from "@vahak/core/dist/custom-types/bid-form";
import ExpectedPrice from "../../../../posting-form/common/expected-price/ExpectedPrice";
import { ExpectedAmountType, IDs } from "@vahak/core/dist/constants";
import { lorryBidModel } from "../bidFormModels";
import { useUpdateExpectedAmount } from "../../../../../hooks/useUpdateExpectedAmount";

export interface loadDetailsAlreadySelected {
    isLoadAvailable: boolean;
    loadId: number;
    qty: number;
    len: number;
    height: number;
    breadth: number;
    material: string;
    isOdc: boolean;
    src: string;
    dest: string;
    srcId: number;
    destId: number;
    isFixedPrice: boolean;
    lorryTypeId: LORRY_CATEGORIES_ENUM;
    expectedPrice?: number;
}

const INITIAL_VALS: LorryFormVals = {};

/**
 *lorryDetails?: LorryCardV2["lorry_detail"] | LorryMarketDetails | LorryDetailsDataType;
 *LorryCardV2["lorry_detail"] need to change in other places as well
 * Home, marketplace, city - done
 */

interface LorryBidFormProps {
    lorryDetails?: LorryCardV2["lorry_detail"] | LorryMarketDetails | LorryDetailsDataType | LorryListLorryDetailTypes;
    placeholderVals?: LorryFormVals;
    loadDetailsAlreadySelected?: loadDetailsAlreadySelected;
    onSuccess?: (name: string, data: GenericObject) => void;
    onSuccessLorryBid?: () => void;
}

const LorryBidForm = ({
    lorryDetails,
    loadDetailsAlreadySelected,
    onSuccess,
    onSuccessLorryBid
}: LorryBidFormProps) => {
    /**
     * Lorry bidding verification check
     */
    const { ValidateLoadPosting } = useValidateUserStatus();
    const { updateLoadLorryPostingData, openLoadPostingModal } = useLoadLorryPosting();
    const { bidFormConfig, dispatchBid } = useContext(BiddingFormContext);
    const router = useRouter();
    const queryClient = useQueryClient();
    const { sendGAandMoEngageEvent } = useEventTrackerService();
    const { mutateAsync: mutateSubmitLorryBid } = useSubmitLorryBid();

    const {
        isPhoneVerified,
        phoneNum,
        companyId,
        name,
        customerCompanyId,
        userId,
        setShouldPreventLayoutSwitch,
        referralCode
    } = useContext(AppContext);
    const [isSubmittingBid, setIsSubmittingBid] = useState(false);
    const [formik, setFormik] = useState<FormikProps<LorryFormVals>>();
    const formikRef = useRef<FormikProps<LorryFormVals>>(null);
    const { mutateAsync: updateExpectedAmount } = useUpdateExpectedAmount();

    const showExpectedAmountAndBidFlow = !!loadDetailsAlreadySelected;
    const selectLoadStep = showExpectedAmountAndBidFlow ? lorryBidSteps[1] : lorryBidSteps[0];
    const validationSchema = showExpectedAmountAndBidFlow
        ? getLorryValidationSchema(selectLoadStep, {})
        : getLorryBidValidationSchema();

    const { type, transporter_name } = (lorryDetails as LorryDetailsDataType) || {};
    const { type_value, company_name, capacity } = (lorryDetails as LorryMarketDetails) || {};

    const lorryId = lorryDetails?.id;

    const trackLoadEventsNew = (name: string, data: GenericObject) => {
        sendGAandMoEngageEvent({
            name: name,
            data: {
                source_screen: "lorry_bidding_form",
                from_screen: "lorry_market",
                ...data
            }
        });
    };

    const redirectToDashboard = async () => {
        await sleep(2000);
        router.replace(ROUTES.dashboard.getUrl());
    };

    const ga4sendEventForLoadSuccess = (isReposted = false) => {
        sendGAandMoEngageEvent(
            {
                name: GA4EventNames.Post_load_success,
                data: {
                    user_name: name,
                    user_number: userId,
                    source_page: router.pathname.includes("marketplace")
                        ? GA4LoadPostingSource.marketplace_live_lorry_bid_now
                        : GA4LoadPostingSource.home_screen_bid_now,
                    isReposted
                }
            },
            true,
            false
        );
    };

    const handleBidding = async (data: LorryBidFormVals, company_id?: number) => {
        const { success } = await handleSubmitLorryBidResponse(mutateSubmitLorryBid, {
            formData: {
                amount: data?.amount,
                loadId: data?.loadId,
                qty: data?.qty,
                isFixedPrice: data?.isFixedPrice,
                isNegotiable: data?.isNegotiable
            },
            companyId: company_id || customerCompanyId || companyId,
            lorryId: lorryId
        });
        setIsSubmittingBid(false);
        dispatchBid({ type: BID_ACTIONS.LORRY, payload: { open: false } });
        await sleep(200);

        if (success) {
            window.VWO.event(VWOEvents.bid_success, {
                type: "lorry"
            });

            toast.success(
                <>
                    Your bid has been posted successfully! <br /> Contact details will be sent via SMS/WhatsApp once the
                    bid has been accepted.
                </>,
                { autoClose: 4000 }
            );
            // Zoho Pagesense Events
            sendPageSenseEvent("Lorry Bid Submit Success");
            queryClient.invalidateQueries([queryNames.getCompanyPostingAndBiddingLimit]);

            const eventID = getFBEventId(lorryId, companyId);

            window.fbq?.(
                "track",
                "InitiateCheckout",
                {
                    type: "lorry_bid",
                    lorry_id: lorryId,
                    lorry_owner_company_name: company_name || transporter_name,
                    lorry_owner_company_id: lorryDetails?.company_id,
                    load_id: data.loadId,
                    phone_number: phoneNum
                },
                { eventID }
            );
            sendFbCapiEvent({
                event_id: eventID,
                event_name: "InitiateCheckout",
                phone_num: phoneNum,
                custom_data: {
                    type: "lorry_bid",
                    lorry_id: lorryId,
                    lorry_owner_company_name: company_name || transporter_name,
                    lorry_owner_company_id: lorryDetails?.company_id,
                    load_id: data.loadId
                }
            });
            gaSendEvent({
                eventCategory: "Web Bid Form",
                eventAction: "Web Bid Submit Success",
                eventLabel: "Web Bid"
            });

            onSuccessLorryBid?.();
            onSuccess?.(GA4EventNames.MARKET_PLACE["bid_submitted"], {});
            onSuccess?.(GA4EventNames.MARKET_PLACE["lorry_bid_submitted"], {});
            bidFormConfig?.lorry?.onSuccess?.();
        } else if (router.route === "/") {
            await redirectToDashboard();
        }
        const loadLorryId = router.query["loadbid"] || router.query["lorrybid"];
        if (loadLorryId) {
            delete router.query["lorrybid"];
            delete router.query["oppcmpid"];
            delete router.query["utm_medium"];
            delete router.query["utm_source"];
            delete router.query["_branch_match_id"];
            delete router.query["_branch_referrer"];
            delete router.query["r"];
            delete router.query["u_i"];
            delete router.query["utm_campaign"];
            await router.replace(router, { query: "" }, { shallow: true, scroll: false });
        }
        setShouldPreventLayoutSwitch?.(false);
    };

    const commonPayloadForPostingForm: ILoadLorryPostingContextData = {
        type: "load",
        originationPage: POSTING_FORM_MODAL_ORIGINATION_PAGE.LORRY_BID_FORM,
        isModalOpen: true,
        skipSuccessScreen: true,
        isExpectedAmountMandatory: true,
        category: "re-post",
        selectedLorryType: getLorryDetailsByType(type_value || type),
        onSuccess: async (response) => {
            await handleBidding({ ...response?.data, loadId: response?.loadId });
        },
        selectedSrcText: lorryDetails?.current_city,
        data: undefined,
        skipNotification: true
    };

    const handleNewLoadFlow = () => {
        formik?.resetForm();
        return ValidateLoadPosting(() => {
            updateLoadLorryPostingData?.({
                ...commonPayloadForPostingForm,
                category: "post",
                submitCtaText: "Post and Submit Bid"
            });
            openLoadPostingModal?.(LOAD_CREATION_FORM_IDS.LOAD_INFO);
        });
    };

    const trackPosting = (result: boolean, loadId: number, loadStatus: LOAD_STATUS) => {
        if (result) {
            onSuccess?.(GA4EventNames.MARKET_PLACE["reposted_load"], {
                load_status: stringOperations(LOAD_STATUS[loadStatus!] ?? "", [
                    "toLowerCase",
                    "replaceSpacesWithUnderscore"
                ])
            });
            trackLoadEventsNew?.(LoadPostingFormEvents["load_post_successfully"], {
                load_id: loadId
            });
            window?.VWO?.event(VWOEvents.post_load_success);
        } else {
            trackLoadEventsNew?.(LoadPostingFormEvents["load_post_failure"], {});
        }
    };

    const submitLorryBid = async (formVals: LorryFormVals) => {
        !loadDetailsAlreadySelected &&
            onSuccess?.(GA4EventNames.MARKET_PLACE["load_selected"], {
                load_status: stringOperations(LOAD_STATUS[formVals?.loadStatus!] ?? "", [
                    "toLowerCase",
                    "replaceSpacesWithUnderscore"
                ])
            });
        if (loadDetailsAlreadySelected) {
            const { isLoadAvailable, loadId, qty } = loadDetailsAlreadySelected;
            await handleBidding({ ...formVals, loadId, qty });
            // !loadDetailsAlreadySelected?.expectedPrice &&
            //     formVals.amount &&
            //     updateExpectedAmount({
            //         expected_amount: Number(formVals.amount),
            //         expected_type: ExpectedAmountType.FIXED_PRICE,
            //         load_id: loadId
            //     });
        } else if (
            formVals.loadId &&
            formVals.loadStatus === LOAD_STATUS.ACTIVE &&
            !!formVals?.selectedLoad?.values?.expectedAmount
            // (formVals.isTapNgoLoad ? !!formVals?.selectedLoad?.values?.expectedAmount : true)
        ) {
            sendGAandMoEngageEvent({ name: "Marketplace_bid_now_web", data: { value: 1 } }, true, false);
            // load is active and expected price is available to use as bid price
            await handleBidding(formVals);
        } else {
            ValidateLoadPosting(() => {
                updateLoadLorryPostingData?.({
                    ...commonPayloadForPostingForm,
                    data: formik?.values?.selectedLoad,
                    submitCtaText: "Update and Submit Bid",
                    ...(formVals.loadStatus === LOAD_STATUS.ACTIVE &&
                        !formVals?.selectedLoad?.values?.expectedAmount && {
                            category: "edit"
                        }),
                    onSuccess: async (response) => {
                        await handleBidding({ ...response?.data, loadId: response?.loadId });
                        trackPosting(true, response?.loadId!, formVals.loadStatus!);
                    }
                });
                openLoadPostingModal?.(LOAD_CREATION_FORM_IDS.LOAD_INFO);
            }, false);
        }
    };

    const onSubmitForm = async (formVals: LorryFormVals) => {
        setIsSubmittingBid(true);
        await submitLorryBid(formVals);
    };

    const getLorryInfoSlot = () => {
        const dataV1 = lorryDetails as LorryCardV2["lorry_detail"];
        return (
            <span className={Styles.lorryCard}>
                {!!lorryDetails?.id && (
                    <Lorry
                        bodyType={dataV1?.body_type}
                        lorryType={dataV1?.type_value!}
                        isVerified={dataV1?.is_verified}
                        lorryId={dataV1?.id}
                        lorryModel={dataV1?.lorry_model}
                        lorryNumber={dataV1?.number}
                    />
                )}
            </span>
        );
    };

    return (
        <div className={Styles.main}>
            <FormContainer
                type="lorry"
                activeStep={selectLoadStep}
                fullScreenSheet={selectLoadStep.id === lorryBidModel.load.id}
                formNode={
                    <Formik
                        initialValues={{
                            ...INITIAL_VALS,
                            phoneNum,
                            companyName: name,
                            lorryCapacity: capacity,
                            loadQuantity: loadDetailsAlreadySelected?.qty,
                            referralCode
                        }}
                        validationSchema={validationSchema}
                        onSubmit={onSubmitForm}
                        validateOnChange
                        innerRef={formikRef}
                        trackingData={{
                            lorry_id: lorryId
                        }}
                    >
                        {(formik) => {
                            const getActionText = () => {
                                if (formik?.values?.loadId && LOAD_STATUS.ACTIVE != formik?.values?.loadStatus!) {
                                    return "Update and continue";
                                }
                                return isSubmittingBid ? "Submitting..." : "Bid Now";
                            };

                            const handleExpectedAmount: React.ChangeEventHandler<HTMLInputElement> = (e) => {
                                e.target.value = removeNonNumeric(e.target.value).slice(0, 7);
                                formik.handleChange(e);
                            };

                            return (
                                <Form
                                    onChange={() => setFormik(formik)}
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        setFormik(formik);
                                        formik.submitForm();
                                    }}
                                    className={Styles.form}
                                >
                                    {selectLoadStep.id === lorryBidModel.price.id && (
                                        <div className={Styles.priceForm}>
                                            <ExpectedPrice
                                                expectedAmount={formik.values.amount}
                                                expectedAmountFieldName={"amount"}
                                                onChange={handleExpectedAmount}
                                                id={IDs.postingForm.expectedAmount}
                                                errorMsg={formik.touched.amount ? formik.errors.amount : ""}
                                                quantity={loadDetailsAlreadySelected?.qty}
                                                isRequired
                                            />
                                        </div>
                                    )}
                                    {selectLoadStep.id === lorryBidModel.load.id && (
                                        <SelectLoadForm
                                            formikConfig={formik}
                                            lorryDetails={lorryDetails}
                                            onClickPostNewLoad={handleNewLoadFlow}
                                            lorryInfoSlot={getLorryInfoSlot()}
                                        />
                                    )}
                                    <div className={Styles.formAction}>
                                        <Button
                                            disabled={!(formik.isValid && formik.dirty)}
                                            type="submit"
                                            id={"mktplace_lorry_bid_final_submit_btn"}
                                            blockBtn
                                        >
                                            {getActionText()}
                                        </Button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                }
            />
        </div>
    );
};

export default LorryBidForm;
